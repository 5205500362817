<script setup>
import { ref, reactive, onMounted } from "vue";
import * as generalAPI from "@/http/general";
import { ElNotification } from "element-plus";
import moment from "@/helpers/moment";
import ClientModal from "../clients/ClientModal.vue";
const loading = ref(false);
const requestQuery = reactive({
  name: null,
  certified: null,
  sex: null,
  address: null,
  origin: null,
  min_height: null,
  max_height: null,
  education_level: null,
  occupation: null,
  religion: null,
  children: null,
  housing_type: null,
  shape: null,
  drink: null,
  read: null,
  smoke: null,
});
const age = reactive({
  min_age: null,
  max_age: null,
});
const tableData = ref([]);
const paginationData = reactive({
  lastPage: "",
  from: "",
  to: "",
  total: "",
  page: 1,
  perPage: 10,
  currentPage: "",
});

const openDialog = ref(false);
const clientData = ref();
const cities = ref([]);
const occupations = ref([]);
const origins = ref([]);
const handleDetailsModal = (item) => {
  clientData.value = item;
  openDialog.value = true;
};

onMounted(() => {
  getCities();
  getOccupations();
  getOrigins();
});

/* const noCaseSensitive = (data, value) => {
  data.value = cities.value.filter((item) => item.label.toLowerCase().includes(value));
}; */

const handleSearch = async () => {
  try {
    loading.value = true;
    const response = await generalAPI.getClients(
      paginationData.page,
      paginationData.perPage,
      requestQuery,
      age
    );
    tableData.value = response.data;
    paginationData.lastPage = response.last_page;
    paginationData.from = response.from;
    paginationData.to = response.to;
    paginationData.total = response.total;
    paginationData.currentPage = response.current_page;
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les utilisateurs n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const getOrigins = async () => {
  try {
    const response = await generalAPI.getOrigins();
    origins.value = Array.from({ length: response.length }).map((_, idx) => ({
      value: `${response[idx]}`,
      label: `${response[idx]}`,
    }));
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les pays n'ont pas pu être chargés",
      type: "error",
    });
  }
};
const getOccupations = async () => {
  try {
    const response = await generalAPI.getOccupations();
    occupations.value = Array.from({ length: response.length }).map(
      (_, idx) => ({
        value: `${response[idx]}`,
        label: `${response[idx]}`,
      })
    );
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les professions n'ont pas pu être chargées",
      type: "error",
    });
  }
};
const getCities = async () => {
  try {
    const response = await generalAPI.getCities();
    cities.value = Array.from({ length: response.length }).map((_, idx) => ({
      value: `${response[idx]}`,
      label: `${response[idx]}`,
    }));
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les villes n'ont pas pu être chargés",
      type: "error",
    });
  }
};
</script>

<template>
  <el-card>
    <div class="block text-center">
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 row-cols-xl-5">
        <div class="col mb-4">
          <label class="d-block">Je veux</label>
          <el-select
            v-model="requestQuery.sex"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Homme" value="M" />
            <el-option label="Femme" value="F" />
            <el-option label="Autre" value="X" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Pour ?</label>
          <el-select
            v-model="requestQuery.relation_type"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Relation sérieuse" value="serious" />
            <el-option label="flirt" value="flirt" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Ville de résidence</label>
          <el-select-v2
            v-model="requestQuery.address"
            filterable
            :options="cities"
            placeholder="Choisir une ville"
            size="large"
          />
        </div>
        <div class="col mb-4">
          <label class="d-block">Pays d'origine</label>
          <el-select-v2
            v-model="requestQuery.origin"
            filterable
            :options="origins"
            placeholder="Choisir un pays"
            size="large"
          />
        </div>
        <div class="col mb-4">
          <label class="d-block">Age voulu</label>
          <div class="d-flex">
            <el-select
              class="col-6"
              v-model="age.min_age"
              placeholder="Choisir un age"
              size="large"
            >
              <el-option
                v-for="(_, index) in 63"
                :key="index"
                :label="index + 18 + ' ans'"
                :value="index + 18"
              />
            </el-select>
            <el-select
              class="col-6"
              v-model="age.max_age"
              placeholder="Choisir un age"
              size="large"
            >
              <el-option
                v-for="(_, index) in 63"
                :key="index"
                :label="index + 18 + ' ans'"
                :value="index + 18"
              />
            </el-select>
          </div>
        </div>
        <div class="col mb-4">
          <label class="d-block">Taille voulue</label>
          <div class="d-flex">
            <el-select
              class="col-6"
              v-model="requestQuery.min_height"
              placeholder="Choisir une taille"
              size="large"
            >
              <el-option
                v-for="(_, index) in 70"
                :key="index"
                :label="index + 150 + ' cm'"
                :value="index + 150"
              />
            </el-select>
            <el-select
              class="col-6"
              v-model="requestQuery.max_height"
              placeholder="Choisir une taille"
              size="large"
            >
              <el-option
                v-for="(_, index) in 70"
                :key="index"
                :label="index + 150 + ' cm'"
                :value="index + 150"
              />
            </el-select>
          </div>
        </div>
        <div class="col mb-4">
          <label class="d-block">Niveau d'études</label>
          <el-select
            v-model="requestQuery.education_level"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Sans diplome" value="none" />
            <el-option label="CEP" value="CEP" />
            <el-option label="BEPC" value="BEPC" />
            <el-option label="BAC" value="BAC" />
            <el-option label="Licence" value="Licence" />
            <el-option label="Bac + 5" value="Bac + 5" />
            <el-option label="Doctorat" value="Doctorat" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Profession</label>
          <el-select-v2
            v-model="requestQuery.occupation"
            filterable
            :options="occupations"
            placeholder="Choisir une profession"
            size="large"
          />
        </div>
        <div class="col mb-4">
          <label class="d-block">Religion</label>
          <el-select
            v-model="requestQuery.religion"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Islam" value="Islam" />
            <el-option label="Christianisme" value="Christianisme" />
            <el-option
              label="Religions traditionnelles"
              value="Religions traditionnelles"
            />
            <!-- <el-option label="Autre" value="Autre" /> -->
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Enfants</label>
          <el-select
            v-model="requestQuery.children"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Sans" :value="0" />
            <el-option label="Avec" :value="1" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Vis</label>
          <el-select
            v-model="requestQuery.housing_type"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Seul" value="Seul" />
            <el-option label="Colocation" value="En colocation" />
            <el-option label="Avec mes parents" value="Chez mes parents" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Forme</label>
          <el-select
            v-model="requestQuery.shape"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Mince" value="Mince" />
            <el-option label="Enrobé" value="Enrobé" />
            <el-option label="Ni mince ni enrobé" value="Ni mince ni enrobé" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Fume</label>
          <el-select
            v-model="requestQuery.smoke"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Jamais" value="Jamais" />
            <el-option label="Occasionnellement" value="Occasionnellement" />
            <el-option label="Régulièrement" value="Regulierement" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Bois</label>
          <el-select
            v-model="requestQuery.drink"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Jamais" value="Jamais" />
            <el-option label="Occasionnellement" value="Occasionnellement" />
            <el-option label="Régulièrement" value="Regulierement" />
          </el-select>
        </div>
        <div class="col mb-4">
          <label class="d-block">Lis</label>
          <el-select
            v-model="requestQuery.read"
            placeholder="Choisir"
            size="large"
          >
            <el-option label="Jamais" value="Jamais" />
            <el-option label="Occasionnellement" value="Occasionnellement" />
            <el-option label="Régulièrement" value="Regulierement" />
          </el-select>
        </div>
      </div>
      <el-button
        class="mx-auto mt-3"
        size="large"
        type="primary"
        :loading="loading"
        @click="handleSearch"
      >
        Rechercher
      </el-button>
    </div>
    <ClientModal
      v-model:open="openDialog"
      :showOnly="true"
      :clientData="clientData"
      @onClose="() => {}"
    />
    <hr />
    <Loader :loading="loading" />
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Pseudo</th>
          <th scope="col">Téléphone</th>
          <th scope="col">% Profil</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in tableData" :key="index">
          <tr :class="item?.certified ? 'bg-success bg-opacity-25' : ''">
            <td>
              {{ moment(item?.created_at).format("Do MMMM YYYY à HH:mm") }}
            </td>
            <td>{{ item?.name }}</td>
            <td>{{ item?.phone }}</td>
            <td>{{ item?.profile_completion }}</td>
            <td>
              <el-button type="info" @click="handleDetailsModal(item)">
                Détails
              </el-button>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="d-flex justify-content-between align-items-center m-3">
      <ul class="pagination pointer">
        <li>
          <p
            @click="paginationData.page--, handleSearch()"
            aria-label="Previous"
          >
            <span aria-hidden="true">«</span>
          </p>
        </li>
        <template v-for="(page, index) in paginationData.lastPage" :key="index">
          <li :class="{ active: page === paginationData.currentPage }">
            <p
              @click="
                page !== paginationData.currentPage &&
                  ((paginationData.page = page), handleSearch())
              "
            >
              {{ page }}
            </p>
          </li>
        </template>
        <li>
          <p @click="paginationData.page++, handleSearch()" aria-label="Next">
            <span aria-hidden="true">»</span>
          </p>
        </li>
      </ul>
      <div class="">
        <em
          >Affichage de {{ paginationData.from }} à {{ paginationData.to }} (sur
          {{ paginationData.total }} éléments)</em
        >
      </div>
    </div>
  </el-card>
</template>
